import React, { useContext } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { LogoutButton } from '../../../components/auth/logout-button';
import { ROLE_ADMIN, ROLE_PLANNER, UserProfileContext, isUserProfileInitialized, ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_SALESOPS, ROLE_HOTELIER, ROLE_SUPPLIER } from '../../../userProfileContext';
import './nav-item.scss'

const orgPaths = {
    [ROLE_DESTINATIONMANAGER]: 'destinationprofile',
    [ROLE_AFFILIATE]: 'affiliateorganization',
    [ROLE_HOTELIER]: 'hotelprofile'
}

export const UserNavItem = () => {
    const { userProfile } = useContext(UserProfileContext);
    const history = useHistory();

    return (
        <div className="nav-item">
            <OverlayTrigger
                placement="right"
                trigger="click"
                rootClose={true}
                rootCloseEvent="click"
                overlay={(
                    <Popover id="user-nav-popover">
                        <Popover.Title>
                            <div className="user-nav-name"><b>{`${userProfile.firstName} ${userProfile.lastName}`}</b></div>
                            <div className="user-nav-id">{userProfile.id}</div>
                        </Popover.Title>
                        <Popover.Content>
                            <div>
                                <Button
                                    variant="link"
                                    onClick={() => history.push(`/myprofile/overview`)}
                                    disabled={!isUserProfileInitialized(userProfile)}
                                >
                                    My Profile
                                </Button>
                            </div>
                            {[ROLE_ADMIN,ROLE_AFFILIATE,ROLE_DESTINATIONMANAGER,ROLE_PLANNER].includes(userProfile.role) && (
                                <div>
                                    <Button
                                        variant="link"
                                        onClick={() => history.push(`/${orgPaths[userProfile.role] ?? 'organization'}/${userProfile.organizationId}`)}
                                        disabled={!isUserProfileInitialized(userProfile)}
                                    >
                                        My Organization
                                    </Button>
                                </div>
                            )}
                            {[ROLE_AFFILIATE,ROLE_SALESOPS,ROLE_SUPPLIER].includes(userProfile.role) === false ? (
                                <div>
                                    <Button
                                        variant="link"
                                        onClick={() => history.push(`/${orgPaths[userProfile.role] ?? 'organization'}/${userProfile.role === ROLE_HOTELIER ? userProfile.associatedVenues[0]?.id : userProfile.organizationId}/subscriptions`)}
                                        disabled={!isUserProfileInitialized(userProfile)}
                                    >
                                        Subscriptions & Billing
                                    </Button>
                                </div>
                            ) : null}
                            <div>
                                <LogoutButton variant='link' />
                            </div>
                        </Popover.Content>
                    </Popover>
                )}
            >
                <Button
                    className="btn-round"
                    variant={"light"}
                    data-testid="user-settings-button"
                >
                    {userProfile.firstName && userProfile.lastName ? `${userProfile.firstName[0]}${userProfile.lastName[0]}` : '--'}
                </Button>
            </OverlayTrigger>
        </div>
    );
}