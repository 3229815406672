import React, { useContext } from 'react';
import { UserProfileContext } from '../../userProfileContext';
import './impersonation-watermark.scss';

const ImpersonationWatermark = () => {
  const { userProfile } = useContext(UserProfileContext);

  if (!userProfile.isImpersonating) {
    return null;
  }

  return (
    <div className="impersonation-watermark">
      <span className="impersonation-watermark__text">
        Impersonating User<br/>{userProfile.firstName} {userProfile.lastName}
      </span>
    </div>
  );
};

export default ImpersonationWatermark;
