import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { impersonateUserProfile } from '../../userProfileProvider';
import { useHistory } from 'react-router-dom';

const ImpersonateUserModal = ({ show, handleClose }) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [impersonateResult, setImpersonateResult] = useState({ message: '', error: false });

    const handleSubmit = (e) => {
        impersonateUserProfile({
            userProfileId: email,
            success: () => { 
                setEmail('');
                if (window.location.hash.startsWith('#/admin')) {
                    history.push('/');
                }
                handleClose();
            },
            error: (e) => setImpersonateResult({ message: e, error: true })
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Impersonate User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                {impersonateResult.error && <Form.Text className="text-danger">{impersonateResult.message}</Form.Text>}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    Impersonate
                </Button>
                <Button
                    variant="danger"
                    onClick={() => window.location.reload()}
                >
                    Revert to Admin
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImpersonateUserModal;