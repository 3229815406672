import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronSquareLeft } from '@fortawesome/pro-solid-svg-icons';
import { faAnalytics, faBuilding, faGlobe, faCommentAltEdit, faFileContract, faGraduationCap, faGripHorizontal, faLayerPlus, faMegaphone, faQuestionCircle, faSearch, faToolbox, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { ROLE_ADMIN, ROLE_ADMIN_HOTEL, ROLE_AFFILIATE, ROLE_DESTINATIONMANAGER, ROLE_HOTELIER, ROLE_PLANNER, ROLE_SALESOPS, ROLE_SUPPLIER, UserProfileContext, isUserProfileInitialized } from '../../userProfileContext';
import { NavItem } from './components/nav-item';
import { UserNavItem } from './components/user-nav-item';
import { UpgradeNavItem } from './components/upgrade-nav-item';
import { NotificationContainer } from 'react-notifications';
import navLogoSvg from '../../images/nav-logo.svg';
import { NavContext } from '../../navProvider';
import { UpgradePromptModal } from '../../components/upgrade-prompt/modal';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { LocalNavContainer } from './components/local-navbar';
import { NotificationsNavItem } from './components/notifications-nav-item';
import { FeatureContext } from '../../featureProvider';
import { CreateRfpNavItem } from './components/create-rfp-nav-item';
import { InvitationModal } from '../../components/invitations/invitation-modal';
import { Routes } from '../../views/routes';
import './index.scss';
import { MultiRoleNavItem } from './components/multi-role-nav-item';
import { HotelierNotAssociated } from '../../components/hoteliers/not-associated';
import { faUserSecret } from '@fortawesome/pro-duotone-svg-icons';
import ImpersonateUserModal from '../../components/admins/impersonate-user-modal';
import ImpersonationWatermark from '../../components/watermark/impersonation-watermark';

const clientNavigate = (userProfileId, location) => {
	if (window.hopSkipConfig.api.environment === 'Prod') {
		window.analytics.page(location.pathname);
	}
	window.Intercom("update");
}

const iconMap = {
	newrfp: { icon: faLayerPlus },
    search: { icon: faSearch },
    board: { icon: faGripHorizontal },
    history: { icon: faFileContract },
	docs: { icon: faFileContract },
    toolbox: { icon: faToolbox },
    reports: { icon: faAnalytics },
	promo: { icon: faMegaphone, style: { transform: 'rotate(336deg)' } },
	building: { icon: faBuilding },
	destination: { icon: faGlobe },
	impersonate: { icon: faUserSecret, activeStyle: { color: 'red' } },
}

export const SidebarNavContainer = () => {
	const location = useLocation();
	const history = useHistory();
	const { userProfile } = useContext(UserProfileContext);
    const { getFeatureByKey, promptUserToUpgrade } = useContext(FeatureContext);
    const edcenterFeature = userProfile.role === 'Planner' ? getFeatureByKey('EDCENTER') : { key: 'EDCENTER' };
	const isAuthenticated = useIsAuthenticated();
    const { currentGlobalNav, currentLocalNav } = useContext(NavContext);
    const [sidebarOpen, setSidebarOpen] = useState(false);
	const [showInvitationModal, setShowInvitationModal] = useState(false);
	const [showImpersonateModal, setShowImpersonateModal] = useState(false);

	useEffect(() => {
		if (userProfile.id && location) {
			window.scroll(0, 0);
			clientNavigate(userProfile.id, location);
		}
	}, [userProfile.id, location]);

    const onClickToggle = () => {
        setSidebarOpen(s => !s);
    }

    return (
        <div id="app-container" className={sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}>
            <div id="toggle-container">
                <Button
                    variant="dark"
                    onClick={onClickToggle}
                >
                    <FontAwesomeIcon
                        icon={sidebarOpen ? faChevronSquareLeft : faBars}
                    />
                </Button>
            </div>
			{isAuthenticated && (
				<div id="sidebar-container">
					{isUserProfileInitialized(userProfile) && (
						<>
							<div className="sidebar-nav top">
								<div className="logo">
									<a href="https://www.myhopskip.com" target="_blank" rel="noopener noreferrer">
										<img alt="HopSkip Logo" src={navLogoSvg} />
									</a>
								</div>
								{[ROLE_SALESOPS, ROLE_ADMIN_HOTEL].includes(userProfile.role) === false && (
									<NotificationsNavItem />
								)}
								{currentGlobalNav.navItems.filter(navItem => navItem.path).map((navItem, i) => {
									if (navItem.iconKey === 'newrfp') {
										return (
											<CreateRfpNavItem
												key={i}
												icon={iconMap[navItem.iconKey].icon}
												text={navItem.title}
												iconStyle={iconMap[navItem.iconKey].style}
											/>
										);
									}
									if (userProfile.role === ROLE_ADMIN && navItem.multiRole) {
										return (
											<MultiRoleNavItem
												key={i}
												icon={iconMap[navItem.iconKey].icon}
												text={navItem.title}
												iconStyle={iconMap[navItem.iconKey].style}
												options={[...navItem.options]}
											/>
										);
									}
									if (navItem.featureKey) {
										const feature = getFeatureByKey(navItem.featureKey);
										if (!feature) {
											return (
												<NavItem
													key={i}
													icon={iconMap[navItem.iconKey].icon}
													text={navItem.title}
													onClick={() => {
														promptUserToUpgrade(true, navItem.featureKey);
													}}
													iconStyle={iconMap[navItem.iconKey].style}
												/>
											);
										}
									}
									if (!!navItem.previewKey && !userProfile.previewExperiences?.[navItem.previewKey]) return null;
									return (
										<NavItem
											key={i}
											icon={iconMap[navItem.iconKey].icon}
											text={navItem.title}
											target={navItem.path}
											iconStyle={iconMap[navItem.iconKey].style}
										/>
									);
								})}
								{(userProfile.role === ROLE_ADMIN || userProfile.isImpersonating) && (
									<>
										<NavItem
											icon={iconMap.impersonate.icon}
											text="Impersonate"
											onClick={() => setShowImpersonateModal(true)}
											iconStyle={userProfile.isImpersonating ? iconMap.impersonate.activeStyle : {}}
										/>
										<ImpersonateUserModal show={showImpersonateModal} handleClose={() => setShowImpersonateModal(false)} />
									</>
								)}
							</div>
							<div className="sidebar-nav bottom">
								{([ROLE_ADMIN, ROLE_HOTELIER, ROLE_DESTINATIONMANAGER].includes(userProfile.role) || (userProfile.role === ROLE_PLANNER && userProfile.accountType)) && (
									<NavItem
										icon={faGraduationCap}
										text="Contracting Academy"
										onClick={() => {
											if (edcenterFeature) {
												history.push('/edcenter/contracts');
											} else {
												promptUserToUpgrade(true, 'EDCENTER');
											}
										}}
									/>
								)}
								{(userProfile.role === ROLE_SUPPLIER && (
									<NavItem
										icon={faUserPlus}
										iconStyle={{ marginLeft: '8px' }}
										text="Invite"
										target={'/myprofile/overview'}
									/>
								)) || (userProfile.role === ROLE_DESTINATIONMANAGER && (
									<NavItem
										icon={faUserPlus}
										iconStyle={{ marginLeft: '8px' }}
										text="Invite"
										target={`/destinationprofile/${userProfile.organizationId}/destinationmanagers`}
									/>
								)) || (userProfile.role === ROLE_AFFILIATE && (
									<NavItem
										icon={faUserPlus}
										iconStyle={{ marginLeft: '8px' }}
										text="Invite"
										target={`/affiliateorganization/${userProfile.organizationId}/members`}
									/>
								)) || ([ROLE_SALESOPS, ROLE_ADMIN_HOTEL].includes(userProfile.role) === false && (
									<>
										<NavItem
											icon={faUserPlus}
											iconStyle={{ marginLeft: '8px' }}
											text="Invite"
											onClick={() => setShowInvitationModal(true)}
										/>
										<InvitationModal show={showInvitationModal} onClose={() => setShowInvitationModal(false)} />
									</>
								))}
								<NavItem icon={faQuestionCircle} text="Help Center" target="https://help.myhopskip.com/en/" />
								<NavItem
									icon={faCommentAltEdit}
									text="Feedback"
									id={"custom-messenger-launcher"}
									onClick={() => { }}
								/>
								<UserNavItem />
								<UpgradeNavItem />
							</div>
						</>
					)}
				</div>
			)}
			{isAuthenticated && (
				<div id="content-container" className={currentLocalNav.show ? 'with-local-nav' : ''}>
					{currentLocalNav.show && (
						<LocalNavContainer />
					)}
					<Container fluid id="view-container">
						<Routes />
						<NotificationContainer />
						<UpgradePromptModal />
						{userProfile.role === ROLE_HOTELIER && (
							<HotelierNotAssociated />
						)}
						<ImpersonationWatermark />
					</Container>
				</div>
			)}
        </div>
    );
}