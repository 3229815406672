import React, { useEffect, useState } from 'react';
import { callApi } from './apiProvider';
import { ROLE_ADMIN, UserProfileContext } from './userProfileContext';
import { getVenueLists, useVenueLists } from './services/organizations';
import { userProfileStore } from './userProfileStore';

export const UserProfileStatus = {
    initial: 'initial',
    anonymous: 'anonymous',
    loading: 'loading',
    loaded: 'loaded',
};

export const UserProfileProvider = ({ children }) => {
    const [userProfileStatus, setUserProfileStatus] = useState(UserProfileStatus.initial);
    const [userProfile, setUserProfile] = useState(userProfileStore.getState());
    const [venueLists, setVenueLists] = useVenueLists(userProfile.organizationId);

    useEffect(() => {
        return userProfileStore.subscribe(setUserProfile);
    }, []);

    useEffect(() => {
        if (!!userProfile.organizationId) {
            setVenueLists(getVenueLists(userProfile.organizationId));
        }
    }, [userProfile.organizationId, setVenueLists]);

    const updatePreviewExperience = ({ userProfileId, previewExperienceKey, enable }) => {
        callApi(
            `/api/userprofiles/${userProfileId ?? userProfile.id}/previewexperiences/${previewExperienceKey}/${enable ? 'true' : 'false'}`,
            {
                method: 'PUT'
            },
            true
        );
    }

    return (
        <UserProfileContext.Provider value={{ userProfile, setUserProfile, userProfileStatus, setUserProfileStatus, updatePreviewExperience, venueLists, setVenueLists }}>
            {children}
        </UserProfileContext.Provider>
    );
}

export const impersonateUserProfile = ({ userProfileId, success, error }) => {
    callApi(
        `/api/userprofiles/${userProfileId}`,
        { method: 'GET' },
        true
    ).then(r => {
        if (r.data.role === ROLE_ADMIN) {
            if (typeof(error) === 'function') {
                error('Admins cannot be impersonated.');
            }
            return;
        }
        userProfileStore.setState({
            ...r.data,
            isImpersonating: true,
            licenseTier: r.data.licenseInfo?.tier,
            licenseType: r.data.licenseInfo?.type,
            commissionVisible:
                r.data.commissionWaived
                    ? false
                    : (r.data.isPaidSubscriber && (r.data.commissionEligible || r.data.rebateEligible)),
        });
        if (typeof(success) === 'function') { 
            success(r.data);
        }
    });
}