import React, { useContext, useEffect, useState } from 'react';
import { Client as ConversationsClient } from '@twilio/conversations';
import { callApi } from "./apiProvider";
import { ChatContext } from './chatContext';
import { faGlobe, faHotel, faLink, faRibbon } from '@fortawesome/pro-solid-svg-icons';
import { UserProfileContext } from './userProfileContext';

const getChatToken = (userId) => {
    return callApi(
        `/msg/api/chat/token`,
        {
            method: 'POST',
            data: { userId }
        },
        true
    );
}

export const getConversation = (uniqueName) => {
    // { conversation: {}, participants: [] }
    return callApi(
        `/msg/api/conversations/${uniqueName}`,
        {
            method: 'GET'
        },
        true
    );
}

export const conversationTemplateTypes = {
    hotel: { key: 'hotel', icon: faHotel, sortIndex: 400 },
    dmo: { key: 'dmo', icon: faGlobe, sortIndex: 100 },
    chain: { key: 'chain', icon: faLink, sortIndex: 200 },
    affiliate: { key: 'affiliate', icon: faRibbon, sortIndex: 300 },
}

export const getTemplateTypeForContact = supplierContact => {
    if (supplierContact.role === 'dmo' || supplierContact.venueMatchCriteria['geolocation']) {
        return conversationTemplateTypes.dmo;
    }
    if (['gso','nso'].includes(supplierContact.role) || supplierContact.venueMatchCriteria['chainId']) {
        return conversationTemplateTypes.chain;
    }
    if (supplierContact.role === 'soa' || supplierContact.venueMatchCriteria['propertySeller']) {
        return conversationTemplateTypes.affiliate;
    }
    return conversationTemplateTypes.dmo;
}

export const getMessagingStateData = messagingState => {
    switch(messagingState) {
        case 'authenticating':
            return { ready: false, message: 'Logging in to chat', badgeVariant: 'info' };
        case 'connecting':
            return { ready: false, message: 'Connecting to chat', badgeVariant: 'warning' };
        case 'connected':
            return { ready: true, message: 'Connected', badgeVariant: 'success' };
        case 'disconnecting':
            return { ready: false, message: 'Disconnecting', badgeVariant: 'warning' };
        case 'disconnected':
            return { ready: false, message: 'Disconnected', badgeVariant: 'danger' };
        case 'denied':
            return { ready: false, message: 'You do not have access to this conversation', badgeVariant: 'danger' };
        default:
            return { ready: false, message: '', badgeVariant: 'info' };
    }
}

export const ChatProvider = ({ children }) => {
    const { userProfile } = useContext(UserProfileContext);
    const [chatClient, setChatClient] = useState(null);
    const [chatClientUserProfileId, setChatClientUserProfileId] = useState(null);
    const [chatClientInitialized, setChatClientInitialized] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [unreadConversations, setUnreadConversations] = useState([]);
  
    useEffect(() => {
        if (chatClientInitialized) {

            const updateUnreadConversation = (conversation) => {
                conversation.getUnreadMessagesCount().then(count => {
                    if (count > 0) {
                        conversation.getMessages(1).then(messages => {
                            setUnreadConversations(s => {
                                return [
                                    ...s.filter(c => c.uniqueName !== conversation.uniqueName),
                                    {
                                        uniqueName: conversation.uniqueName,
                                        friendlyName: conversation.friendlyName,
                                        unreadMessagesCount: count || 0,
                                        lastMessage: messages.items.length ? {
                                            dateCreated: messages.items[0]?.dateCreated,
                                            author: messages.items[0]?.author
                                        } : { }
                                    }
                                ];
                            });
                        });
                    } else {
                        setUnreadConversations(s => s.filter(c => c.uniqueName !== conversation.uniqueName));
                    }
                }).catch(() => {
                    setUnreadConversations(s => s.filter(c => c.uniqueName !== conversation.uniqueName));
                });
            }

            const messageAdded = (message) => {
                if (message.conversation?.uniqueName) {
                    window.setTimeout(() => { updateUnreadConversation(message.conversation); }, 1500);
                }
            }

            const conversationAdded = conversation => {
                setConversations(p => [
                    ...p.filter(c => c.uniqueName !== conversation.uniqueName),
                    conversation
                ]);
                updateUnreadConversation(conversation);
            };
            
            const conversationUpdated = ({conversation}) => {
                setConversations(p => [
                    ...p.filter(c => c.uniqueName !== conversation.uniqueName),
                    conversation
                ]);
                updateUnreadConversation(conversation);
            }

            const conversationJoined = conversation => {
                setConversations(p => [
                    ...p.filter(c => c.uniqueName !== conversation.uniqueName),
                    conversation
                ]);
                updateUnreadConversation(conversation);
            };

            const conversationLeft = conversation => {
                setConversations(p => {
                    return p.filter(c => c.uniqueName !== conversation.uniqueName);
                });
                setUnreadConversations(s => s.filter(c => c.uniqueName !== conversation.uniqueName));
            };

            const connectionStateChanged = connectionState => {
                //'connecting' | 'connected' | 'disconnecting' | 'disconnected' | 'denied'
                if (connectionState === 'disconnected') {
                    getChatToken(userProfile.id).then(r => chatClient.updateToken(r.data));
                }
            }

            const tokenAboutToExpire = () => {
                getChatToken(userProfile.id).then(r => chatClient.updateToken(r.data));
            }

            const tokenExpired = () => {
                getChatToken(userProfile.id).then(r => chatClient.updateToken(r.data));
            }

            chatClient.on('conversationAdded', conversationAdded);
            chatClient.on('conversationJoined', conversationJoined);
            chatClient.on('conversationLeft', conversationLeft);
            chatClient.on('conversationUpdated', conversationUpdated);
            chatClient.on('connectionStateChanged', connectionStateChanged);
            chatClient.on('messageAdded', messageAdded);
            chatClient.on('tokenAboutToExpire', tokenAboutToExpire);
            chatClient.on('tokenExpired', tokenExpired);

            return (() => {
                chatClient.off('conversationAdded', conversationAdded);
                chatClient.off('conversationJoined', conversationJoined);
                chatClient.off('conversationLeft', conversationLeft);
                chatClient.off('conversationUpdated', conversationUpdated);
                chatClient.off('connectionStateChanged', connectionStateChanged);
                chatClient.off('messageAdded', messageAdded);
                chatClient.off('tokenAboutToExpire', tokenAboutToExpire);
                chatClient.off('tokenExpired', tokenExpired);
            });
        }
    }, [chatClientInitialized, chatClient, userProfile.id]);

    useEffect(() => {
        if (userProfile.id) {
            console.log(`initializing chat client for ${userProfile.id}`);
            getChatToken(userProfile.id)
            .then(r => {
                try {
                    const client = new ConversationsClient(r.data, { logLevel: 'info' });
                    setChatClient(client);
                    setChatClientInitialized(true);
                    setChatClientUserProfileId(userProfile.id);
                    console.log(`chat client initialized for ${userProfile.id}`);
                }
                catch(reason) {
                    setChatClientUserProfileId(null);
                    console.log(`chat client initialization failed: ${reason}`);
                }
            }).catch(reason => {
                setChatClientUserProfileId(null);
                console.log(`chat client token failed: ${reason}`);
            });
        }
    }, [userProfile.id]);

    const reInitializeChatClient = (userProfileId) => {
        if (userProfileId) {
            console.log(`initializing chat client for ${userProfileId}`);
            getChatToken(userProfileId)
            .then(r => {
                try {
                    const client = new ConversationsClient(r.data, { logLevel: 'info' });
                    setChatClient(client);
                    setChatClientInitialized(true);
                    setChatClientUserProfileId(userProfileId);
                    console.log(`chat client initialized for ${userProfileId}`);
                }
                catch(reason) {
                    setChatClientUserProfileId(null);
                    console.log(`chat client initialization failed: ${reason}`);
                }
            }).catch(reason => {
                setChatClientUserProfileId(null);
                console.log(`chat client token failed: ${reason}`);
            });
        }
    }
    
    const parseConversationDisplayName = (conversationFriendlyName) => {
        if (conversationFriendlyName) {
            const parts = conversationFriendlyName.split('|');
            return { rfpName: parts[0], venueName: parts.length > 1 ? parts[1] : 'Planner Internal Chat' };
        }
        return 'New RFP';
    }
    
    const parseConversationUniqueName = (conversationUniqueName) => {
        const parts = conversationUniqueName.split('|');
        return { rfpId: parts[0], venueId: parts.length > 1 ? parts[1] : '' };
    }

    return (
        <ChatContext.Provider value={{
            chatClient,
            chatClientInitialized,
            chatClientUserProfileId,
            conversations,
            unreadConversations,
            reInitializeChatClient,
            parseConversationDisplayName,
            parseConversationUniqueName
        }}>
            {children}
        </ChatContext.Provider>
    );
}