import { formatDuration } from "date-fns";
import { UnitedStatesDollar } from "../currencyProvider";

export const weekDayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const comparableHotelSources = {
    CompSet: 'CompSet',
    Proximity: 'Proximity',
}

export const savingsKeys = {
    rooms: 'rooms',
    meeting: 'meeting',
    concession: 'concession'
}

export const formatCurrency = (c, currency, defaultZero) => {
    return `${currency?.symbol || UnitedStatesDollar.symbol}${formatNumber(c, defaultZero)}`;
}

export const formatNumber = (n, defaultZero, decimals) => {
    if ((n && isNaN(n) === false) || n === 0) {
        return Number(n).toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: decimals || 0 });
    }
    return defaultZero ? '0' : '-';
}

export const formatNumberMinimumDecimals = (n, defaultZero, decimals) => {
    if ((n && isNaN(n) === false) || n === 0) {
        return Number(n).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: decimals || 0, maximumFractionDigits: decimals || 0 });
    }
    return defaultZero ? '0' : '-';
}

export const formatOrdinalNumber = i => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const formatDurationSeconds = seconds => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return formatDuration({ hours: h, minutes: m, seconds: s });
}

export const formatPhoneText = p => {
    if (p.length === 11) {
        return `(${p.substring(1, 4)}) ${p.substring(4, 7)}-${p.substring(7)}`;
    } else if (p.length === 10) {
        return `(${p.substring(0, 3)}) ${p.substring(3, 6)}-${p.substring(6)}`;
    } else {
        return p;
    }
}

export const formatPhone = p => {
    if (p) {
        const re = /\d+/g;
        const numbersOnly = p.match(re).join('');
        return [ `tel:+${numbersOnly}`, formatPhoneText(numbersOnly) ];
    }
    return [ '', '' ];
}

export const isPhoneNumber = p => {
    if (p) {
        const re = /\d+/g;
        const numbersOnly = p.match(re)?.join('');
        return numbersOnly?.length === 10 || numbersOnly?.length === 11;
    }
    return false;
}

export const formatTypeOption = (options, type) => {
    const option = options.find(o => o.value === type);
    if (option) {
        return option.name;
    }
    return "Not specified";
}

export const isUnsupportedBrowser = () => {
    if (window.navigator.userAgent.includes('Macintosh') && window.navigator.userAgent.includes('Safari') && window.navigator.userAgent.includes('Chrome') === false)
        return true;
    if (window.navigator.userAgent.includes('MSIE') || window.navigator.userAgent.includes('Trident'))
        return true;
    return false;
}

export const sortProposalDates = (c, n) => {
    if (c.preferred)
        return -1;
    
    if (n.preferred)
        return 1;

    if (c.alternate === n.alternate || c.suggested === n.suggested) {
        return c.key > n.key ? 1 : -1;
    }
    
    if (c.alternate)
        return -1;
    
    if (c.suggested)
        return 1;

    if (n.alternate)
        return 1;

    if (n.suggested)
        return -1;
}

export function objectToQueryString(params) {
    return Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
}

export function queryStringToObject(queryString) {
    const o = queryString.split('&').reduce((accumulator, param) => {
        const [key, value] = param.split('=');
        accumulator[key.replace('?','')] = decodeURIComponent(value);
        return accumulator;
    }, {});
    return o;
}

export function metersToMiles(meters) {
    return Number(meters / 1609.34).toFixed(1);
}

export function kilometersToMiles(kilometersToMiles) {
    return Number(kilometersToMiles / 1.60934).toFixed(1);
}

export function milesToKilometers(miles) {
    return Number(miles * 1.609344).toFixed(2);
}

export function formatImageUrl(imageUrl, venueId, dimensionCategory) {
    return `${window.hopSkipConfig.imagesEndpoint.url}/${venueId}/${imageUrl}`;
}

/**
 * Calculates the distance between two geocodes
 * Returns an object with distance calculator function
 */
export function GeoCodeDistanceCalculator() {
    const EarthRadiusInMiles = 3956.0;
    const EarthRadiusInKilometers = 6367.0;
    const ToRadian = v => v * (Math.PI / 180);
    const DiffRadian = (v1, v2) => ToRadian(v2) - ToRadian(v1);

    /**
     * @param  {The latitude of the first geocode} lat1
     * @param  {The longitude of the first geocode}lng1
     * @param  {The latitude of the second geocode} lat2
     * @param  {The longitude of the second geocode} lng2
     * @param  {The distance units i.e mi or km} units="mi"
     */
    const calcDistance = (lat1, lng1, lat2, lng2, units = 'mi') => {
        let radius = 0;
        switch (units.toLowerCase()) {
            case 'm':
                radius = EarthRadiusInKilometers * 1000;
                break;
            case 'km':
                radius = EarthRadiusInKilometers;
                break;
            case 'ft':
                radius = EarthRadiusInMiles * 5282;
                break;
            case 'mi':
            default:
                radius = EarthRadiusInMiles;
        }
        return (
            radius *
            2 *
            Math.asin(
                Math.min(
                    1,
                    Math.sqrt(
                        (Math.sin(DiffRadian(lat1, lat2) / 2.0) ** 2.0) +
                        Math.cos(ToRadian(lat1)) *
                        Math.cos(ToRadian(lat2)) *
                        (Math.sin(DiffRadian(lng1, lng2) / 2.0) ** 2.0)
                    )
                )
            )
        );
    };
    return {
        calculateDistance: calcDistance
    };
}

export const formatHour = (hour) => {
    if (hour === 0) {
        return '12 am';
    }
    return `${hour <= 12 ? hour : hour - 12} ${hour < 12 ? 'a' : 'p'}m`;
}

export const convertTextToHtml = (text) => {
    return text.replace(/\n/g, '<br />');
}

export const byteArrayToBase64 = (byteArray) => {
    return byteArray.toString('base64');
};