class UserProfileStore {
  constructor() {
    this.state = {
      count: 0,
      user: null
    };
    this.setters = new Set();
  }

  subscribe = (setter) => {
    this.setters.add(setter);
    return () => this.setters.delete(setter);
  };

  getState = () => this.state;

  setState = (newState) => {
    this.state = typeof newState === 'function' 
      ? newState(this.state) 
      : { ...this.state, ...newState };
    
    this.setters.forEach(setter => setter(this.state));
  };
}

export const userProfileStore = new UserProfileStore();